<template>
    <div class="aboutUs_page">
        <Banner :adCategory="6"></Banner>
        <div class="breadcrumb_box flex-center">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>
        <div class="module1" id="id1">
            <div class="content">
                <div class="title">公司简介</div>
                <div class="bg">
                    <img class="icon_bg" :src="formData.coverUrl" alt="">
                </div>
                <div class="main">
                    <div class="company_box">
                        <div class="company_name">{{ formData.companyName }}</div>
                        <div class="company_introduction" v-html="formData.content"></div>
                        <div class="gej_introduce Between">
                            <div class="list flex-center">
                                <img src="@/assets/img/aboutUs/3.png" alt="">
                                <div class="name">{{ formData.establishedTimeIntroduce }}</div>
                            </div>
                            <div class="list flex-center">
                                <img src="@/assets/img/aboutUs/3.png" alt="">
                                <div class="name">{{ formData.serverCompanyIntroduce }}</div>
                            </div>
                            <div class="list flex-center">
                                <img src="@/assets/img/aboutUs/3.png" alt="">
                                <div class="name">{{ formData.top500CompanyIntroduce }}</div>
                            </div>
                            <div class="list flex-center">
                                <img src="@/assets/img/aboutUs/3.png" alt="">
                                <div class="name">{{ formData.projectCaseIntroduce }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="introduction flex">
                        <div class="introduction_list">
                            <div class="introduction_list_head">
                                <div class="introduction_title">成立时间</div>
                                <div class="num display">{{ formData.establishedTime.slice(0, 4) }} <div class="unit">年
                                    </div>
                                    <div class="line"></div>
                                </div>
                            </div>
                        </div>
                        <div class="introduction_list">
                            <div class="introduction_list_head">
                                <div class="introduction_title">世界500强企业已服务</div>
                                <div class="num display">{{ formData.top500CompanyNum }} <div class="unit">家</div>
                                </div>
                                <div class="line"></div>
                            </div>
                            <!-- <div class="technology">{{ formData.serverCompanyIntroduce }}</div> -->
                        </div>
                        <div class="introduction_list">
                            <div class="introduction_list_head">
                                <div class="introduction_title">国内500强企业已服务</div>
                                <div class="num display">{{ formData.serverCompanyNum }} <div class="unit">家</div>
                                    <div class="line"></div>
                                </div>
                            </div>
                            <!-- <div class="technology">{{ formData.top500CompanyIntroduce }}</div> -->
                        </div>
                        <div class="introduction_list">
                            <div class="introduction_list_head">
                                <div class="introduction_title">无忧工程案例</div>
                                <div class="num display">{{ formData.projectCaseNum }} <div class="unit">+</div>
                                </div>
                            </div>
                            <!-- <div class="technology">{{ formData.projectCaseIntroduce }}</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2" id="id2">
            <div class="content">
                <div class="title">发展历程</div>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOption">
                        <swiper-slide v-for="(item, index) in formData.developmentPathVOS" :key="index">
                            <div class="swiper_cont">
                                <div class="year">{{ item.developmentTime.slice(0, 4) }}年</div>
                                <div class="honor" v-html="item.receiveHonor">

                                </div>
                                <img class="certificate" v-if="item.imgUrl" :src="item.imgUrl" alt="">
                            </div>
                        </swiper-slide>

                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="@/assets/img/aboutUs/left.png" alt="">
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="@/assets/img/aboutUs/right.png" alt="">
                        </div>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="module3" id="id3">

            <div class="title">荣誉资质</div>
            <div class="main">
                <el-carousel :autoplay="false">
                    <el-carousel-item class="el-car-item" v-for="(list, index) in dataList" :key="index">
                        <div class="carouselData flex-center column" v-for="(imgList, index2) in list" :key="index2"
                            @click="amplification(imgList.honorUrl)">
                            <img class="certificate" :src="imgList.honorUrl" :alt="imgList.title" />
                            <div class="name">{{ imgList.introduce }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="module4" id="id4">
            <div class="title">企业文化</div>
            <div class="content">
                <div class="enterprise_culturee flex flex-wrap">
                    <div class="list" :style="{
            'background': 'url(' + item.backgroundPictureUrl + ')',
            'background-repeat': 'no-repeat',
            'background-size': '100% 100%'
        }" v-for="(item, index) in formData.companyCultureVOS" :key="index">
                        <div class="enterprise_culturee_title">{{ item.cultureTitle }}</div>
                        <div class="enterprise_culturee_line"></div>
                        <div class="enterprise_culturee_details">{{ item.introduce }}</div>

                    </div>
                </div>
            </div>
        </div>
        <div class="bigImg" v-if="isShow == true">
            <div class="mask" @click="isShow = false"></div>
            <div class="img_div">
                <img class="icon_imgUrl" :src="imgUrl" alt="">
                <img class="icon_off pointer" @click="isShow = false" src="@/assets/img/off.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
import Breadcrumb from '@/components/breadcrumb'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    components: {
        Banner,
        Breadcrumb,
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            isShow: false,
            imgUrl: "",
            anchor: 1,
            imgs: require('../assets/img/homes/2.png'),
            breadcrumbList: [
                {
                    name: "关于我们",
                    url: ''
                },
            ],
            developmentHistory: [],
            dataList: [],
            enterpriseCulturee: [],
            swiperOption: {
                slidesPerView: 4,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 400
                                ? 'vertical'
                                : 'horizontal'
                        )
                    }
                },
            },
            formData: {
                establishedTime: ""
            },
        }
    },
    mounted() {
        this.dataList = this.init(this.dataList, 4)
        this.anchor = this.$route.query.id
        this.getData()
        this.returnTop(this.anchor)
        this.breadcrumbList = [
            {
                name: "关于我们",
                url: ''
            },
            {
                name: this.$route.query.name,
                url: ''
            },
        ]
    },
    // 监听路由变化
    watch: {
        $route: "urlName",
    },
    methods: {
        // 监听url参数变化
        urlName() {
            this.dataList = this.init(this.dataList, 4)
            this.anchor = this.$route.query.id
            this.getData()
            this.returnTop(this.anchor)
            this.breadcrumbList = [
                {
                    name: "关于我们",
                    url: ''
                },
                {
                    name: this.$route.query.name,
                    url: ''
                },
            ]
        },
        getData() {
            this.api.findAboutUsInfo().then(res => {
                console.log(res);
                this.formData = res.data
                this.dataList = this.init(this.formData.honorVOS, 4)

            })
        },
        returnTop(anchor) {
            let hrefUrl = window.location.href
            if (hrefUrl.indexOf("/aboutUs") >= 0) {
                if (anchor) {
                    var PageId = document.querySelector("#id" + anchor);
                    window.scrollTo({
                        top: PageId.offsetTop,
                        behavior: "smooth",
                    });

                }

            }
        },
        init(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return dataList = [...newDataList]
        },
        amplification(imgUrl) {
            console.log(imgUrl);
            this.imgUrl = imgUrl
            this.isShow = true
        }
    }
}
</script>
<style lang="scss" scoped>
.aboutUs_page {
    .breadcrumb_box {
        background: #f4f5f5;
    }

    .title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        padding-top: 79px;
        text-align: center;
    }

    .module1 {
        height: 1020px;
        position: relative;

        .icon_bg {
            width: 100%;
            height: 455px;
            margin-top: 40px;

        }

        .main {
            width: 1200px;
            position: absolute;
            top: 388px;
            z-index: 1px;

            .company_box {
                width: 1040px;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.2500);
                margin: 0 auto;
                background: white;
                padding: 37px 40px;
                box-sizing: border-box;

                .company_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    line-height: 24px;
                }

                .company_introduction {
                    width: 960px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                    margin-top: 26px;
                }

                .gej_introduce {
                    margin-top: 34px;

                    .list {
                        img {
                            width: 27px;
                            height: 25px;
                        }

                        .name {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            margin-left: 7px;
                        }
                    }
                }
            }

            .introduction {
                margin-top: 34px;

                .introduction_list:nth-child(1) {
                    margin-left: 0px !important;
                }

                .introduction_list {
                    width: 280px;
                    height: 217px;
                    box-sizing: border-box;
                    margin-left: 27px;

                    .introduction_list_head {
                        border-radius: 10px;
                        padding: 42px 0px;
                        position: relative;

                        .line {
                            width: 1px;
                            height: 88px;
                            background: #DEDEDE;
                            position: absolute;
                            top: 50%;
                            right: 0px;
                            margin-top: -44px;
                        }
                    }

                    .introduction_title {
                        font-size: 16px;
                        color: #333333;
                        line-height: 16px;
                        text-align: center;
                    }

                    .num {
                        font-size: 48px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #E62129;
                        margin-top: 28px;
                        line-height: 30px;
                        text-align: center;

                        .unit {
                            color: #E62129;
                            font-size: 18px;
                            margin-top: 15px;
                        }
                    }

                    .technology {
                        width: 224px;
                        font-size: 16px;
                        color: #333333;
                        line-height: 24px;
                        margin: 0 auto;
                        margin-top: 20px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module2 {
        height: 688px;
        background: #f4f5f5;

        .swiper_box {
            width: 1400px;
            margin-top: 40px;

            .swiper_cont::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 1px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 4px;
            }

            .swiper_cont::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                width: 5px;
                height: 227px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #E62129;
            }

            .swiper_cont::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                width: 5px;
                background: #CCCCCC;
            }

            .swiper_cont {
                width: 324px;
                height: 400px;
                padding-left: 26px;
                padding-top: 7px;
                overflow: hidden;
                overflow-y: scroll;
                box-sizing: border-box;

                .year {
                    font-size: 32px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #E62129;
                    line-height: 32px;
                }

                .honor {
                    width: 260px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 22px;
                }

                .certificate {
                    width: 272px;
                    height: 182px;
                    margin-top: 25px;
                }
            }
        }
    }

    .module3 {
        height: 670px;

        .main {
            width: 1400px !important;
            height: 400px;
            margin: 0 auto;
            margin-top: 50px;

            ::v-deep.el-car-item {
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 106px;
            }

            .carouselData {
                width: 280px;
                height: 368px;
                background: white;
                box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.2500);
                margin-right: 20px;
                cursor: pointer;

                .certificate {
                    max-width: 100%;
                    margin-top: 52px;
                }

                .name {
                    margin-top: 50px;
                    font-size: 16px;
                    color: #333333;
                }
            }

            .carouselData:hover {
                border: 1px solid #E62129 !important;
            }

            .carouselData:last-child {
                margin-right: 0px;
            }

            ::v-deep.el-carousel {
                .el-carousel__container {
                    height: 370px;

                    .el-carousel__arrow {
                        width: 60px;
                        height: 60px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
            }

            ::v-deep.el-carousel__indicators {
                display: none;
            }
        }
    }

    .module4 {
        height: 1030px;
        background: #f4f5f5;

        .enterprise_culturee {
            width: 100%;
            margin-top: 50px;

            .list:hover {
                background: #e62129 !important;

                .enterprise_culturee_details {
                    display: block;
                }
            }

            .list:nth-child(4n+1) {
                margin-left: 0 !important;
            }

            .list {
                width: 280px;
                height: 368px;
                padding-top: 70px;
                padding-left: 46px;
                box-sizing: border-box;
                margin-left: 26px;
                margin-bottom: 28px;

                .enterprise_culturee_title {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 24px;
                }

                .enterprise_culturee_line {
                    width: 47px;
                    height: 3px;
                    background: #FFFFFF;
                    margin-top: 14px;
                }

                .enterprise_culturee_details {
                    width: 176px;
                    height: 63px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-top: 34px;
                    display: none;
                }
            }
        }
    }

    .icon_left_right {
        width: 70px;
        height: 70px;

    }

    .img_div {
        width: 1000px;
        height: 700px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -350px;
        margin-left: -500px;
        z-index: 9999999;

        .icon_imgUrl {
            width: 1000px;
            height: 700px;
        }

        .icon_off {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
</style>
<style>
.swiper-button-prev {
    left: 25px;
}

.swiper-button-next {
    right: 20px;
}

.swiper-wrapper {
    padding-left: 80px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    content: none;
}

.swiper-slide {
    width: 324px !important;
}

.swiper-slide:nth-child(1) {
    border-left: 1px solid #ccc;
}
</style>